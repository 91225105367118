<template>
  <div>
    <v-card flat tile>
      <v-card-title>
        {{$t('settings.lang_editEcTerminal')}}
      </v-card-title>
      <v-divider class="ma-0 pa-0"/>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-select disabled :items="kassenIDs" :label="this.$t('generic.lang_cashierID')" v-model="cashierID" outlined></v-select>
              <v-text-field
                v-model="terminalAliasName"
                :label="$t('generic.lang_alias')"
                @focus="showTouchKeyboard"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                autocomplete="off"
                outlined
              />
            </v-col>

            <v-col lg="4" md="6" sm="12" cols="12">
              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="zvtServerIP"
                            :label="'ZVT Server '+this.$t('generic.lang_ipAddress')"
                            autocomplete="off"
                            required
                            :rules="[rules.required]"
              />

              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="zvtServerPort"
                            :label="$t('generic.lang_zvtServerPort')"
                            autocomplete="off"
                            required
                            type="number"
                            :rules="[rules.required]"
              />
            </v-col>

            <v-col lg="4" md="6" sm="12" cols="12">
              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="terminalIP"
                            :label="$t('generic.lang_ipAddressOfEcTerminal')"
                            autocomplete="off"
                            required
                            :rules="[rules.required]"
              />
            </v-col>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="terminalPort"
                            :label="$t('generic.lang_terminalPort')"
                            autocomplete="off"
                            type="number"
                            min="1"
                            :rules="[rules.required]"
              />
            </v-col>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="terminalPW"
                            :label="$t('generic.lang_terminalPassword')"
                            type="password"
                            autocomplete="off"
              />
            </v-col>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-checkbox v-model="registrationREQ" :label="this.$t('generic.lang_needsRegistration')"></v-checkbox>
            </v-col>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-checkbox v-model="printOnECR" :label="$t('settings.lang_ecZvtPrintOverCashierPrinter')"></v-checkbox>
            </v-col>
            <v-col lg="4" md="6" sm="12" cols="12">
              <v-checkbox v-model="printDailyReport" :label="$t('settings.lang_ecDailyReportOnCashierDaily')"></v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters justify-md="end" justify="center">
            <v-btn color="error" large :loading="loading" :disabled="!valid" @click="deleteData()">
              {{$t('generic.lang_delete')}}
            </v-btn>
            <v-btn color="success" large :loading="loading" :disabled="!valid" @click="update()">
              {{$t('generic.lang_edit')}}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <!-- Control Component -->
    <EditECTerminalControlComponent v-if="zvtServerIP !== null"
                                    :server_ip="zvtServerIP+':'+zvtServerPort"
                                    :terminal_id="parseInt(id)"
                                    :terminal_ip="terminalIP"
                                    :terminal_port="parseInt(terminalPort)"
                                    :terminal_password="terminalPW"
                                    :terminal_ecr="printOnECR"
    ></EditECTerminalControlComponent>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns"
import {mapState} from "vuex";
import EditECTerminalControlComponent from "@/components/settings/sysetmdata/Payment/EditECTerminalControlComponent";

export default {
  name: "EditECTerminalComponent",
  components: {EditECTerminalControlComponent},
  mixins: [mixin],
  computed: {
    ...mapState([
      'cashierIDs',
    ]),
  },
  data() {
    return {
      rules: {
        required: (v) => {
          return !!v || this.$t('generic.lang_requiredField')
        }
      },
      id: null,
      loading: false,
      valid: false,
      kassenIDs: [],
      cashierID: null,
      terminalAliasName: null,
      terminalIP: null,
      terminalPort: null,
      terminalPW: null,
      zvtServerIP: null,
      zvtServerPort: null,
      registrationREQ: false,
      printOnECR: false,
      printDailyReport: false
    }
  },
  methods: {
    getData() {
      this.id = parseInt(this.$route.params.id);
      this.loading = true;
      this.axios.post('get/settings/editECTerminal/', {
        ecTerminalID: this.id,
      }).then(res => {
        this.terminalAliasName = res.data.formfillData.textFields.terminalAliasName;
        this.cashierID = res.data.formfillData.textFields.cashierID;
        this.terminalIP = res.data.formfillData.textFields.terminalIP;
        this.terminalPort = res.data.formfillData.textFields.terminalPort;

        // PARSE
        let ipWithPort = res.data.formfillData.textFields.zvtServerIP;

        if(ipWithPort === null)
          return null;

        if(!ipWithPort.includes(":")) {

        }
        else {
          let data = ipWithPort.split(":");
          this.zvtServerIP = data[0];
          this.zvtServerPort = parseInt(data[1]);
        }

        this.terminalPW = res.data.formfillData.textFields.terminalPW;
        this.registrationREQ = (res.data.formfillData.textFields.registrationREQ !== 0);
        this.printOnECR = (res.data.formfillData.textFields.printOnECR !== 0);
        this.printDailyReport = (res.data.formfillData.textFields.printDailyReport !== 0);

        this.loading = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_error'),
          color: "error"
        });
        this.loading = false;
      })

    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post('update/settings/ecTerminal/', {
        ecTerminalID: this.id,
        terminalAliasName: this.terminalAliasName,
        terminalIP: this.terminalIP,
        terminalPort: this.terminalPort,
        terminalPW: this.terminalPW,//paswword
        zvtServerIP: this.zvtServerIP+':'+this.zvtServerPort,
        registrationREQ: this.registrationREQ,
        printOnECR: this.printOnECR,
        printDailyReport: this.printDailyReport
      }).then(res => {

        Events.$emit("showSnackbar", {
          message: this.$t('settings.lang_terminalAdded'),
          color: "success"
        });
        this.$refs.form.reset();
        this.$router.go(-1)
        this.loading = false;

      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.msg,
          color: "error"
        });
        this.loading = false;
      })
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.loading = true;
      this.$swal({
        title: this.$t('generic.lang_deleteProject'),
        text: this.$t('generic.lang_deleteConfirmationMSG')+'?',
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios.post('delete/settings/ecTerminal/', {
            ecTerminalIDs: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('settings.lang_configsDeleted'),
                color: "success"
              });
              this.$router.go(-1);
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
            this.dialog = false;
            this.loading = false;
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
  },
  mounted() {
    this.kassenIDs = this.cashierIDs.availableCashierIDs;
    this.getData();

  }
}
</script>

<style scoped>

</style>