<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>{{$t('settings.lang_ecTerminalControl')}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="getTerminalInfo">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <b>{{$t('generic.lang_status')}}: </b>
      <v-chip label small :color="(ready ? 'success' : 'error')">
        <span v-if="ready">
          {{ $t('generic.lang_ready') }}  
        </span>
        <span v-else>
          {{ $t('generic.lang_notReady') }}
        </span>
      </v-chip>
    </v-card-text>

    <v-card-text>
      <v-btn @click="diagnosis" :loading="loadingDiagnosis" :disabled="!ready || loadingDiagnosis" color="primary">{{$t('settings.lang_printDiagnosis')}}</v-btn>
      <v-btn @click="dailyReport" :loading="loadingDailyReport" :disabled="!ready || loadingDailyReport" color="primary">{{$t('accounting.lang_Printdaily')}}</v-btn>
      <v-btn @click="unregister" :loading="loadingUnregister" :disabled="!ready || loadingUnregister" color="primary">{{$t('settings.lang_unregisterTerminal')}}</v-btn>
      <v-btn @click="register" :loading="loadingRegister" :disabled="ready || loadingRegister" color="primary">{{$t('settings.lang_registerTerminal')}}</v-btn>
    </v-card-text>

    <!-- ---- REPRINT DIALOG ---- -->
    <v-dialog v-model="showReprintDialog" persistent max-width="700">
      <v-card>
        <v-card-title>{{$t('generic.lang_printingFailed')}}</v-card-title>
        <v-card-text>
          <v-alert text color="primary">{{$t('generic.lang_reprint')}}?</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="showReprintDialog = false">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="reprint" :disabled="loadingReprint" :loading="loadingReprint">{{$t('generic.lang_reprint')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "../../../../config";
import {printDataFromPrinter} from "../../../../plugins/printing/printerController";

export default {
  name: "EditECTerminalControlComponent",

  props: {
    server_ip: String,
    terminal_id: Number,
    terminal_password: String,
    terminal_port: Number,
    terminal_ip: String,
    terminal_ecr: Boolean
  },

  data() {
    return {
      ready: false,
      showReprintDialog: false,
      loading: false,
      loadingDiagnosis: false,
      loadingDailyReport: false,
      loadingReprint: false,
      loadingUnregister: false,
      loadingRegister: false,
      zvtAxios: null,
      reprintPayload: []
    }
  },

  watch: {
    showReprintDialog(val) {
      if (!val)
        this.reprintPayload = [];
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  mounted() {
    //CREATE NEW AXIOS INSTANCE
    this.zvtAxios = this.axios.create({
      baseURL: 'http://' + this.server_ip,
      timeout: 60000000,
      headers: {
        'terminal-id': this.terminal_id,
        'terminal-ip': this.terminal_ip,
        'terminal-port': this.terminal_port,
        'terminal-password': this.terminal_password,
        'terminal-ecr': this.terminal_ecr
      }
    });

    this.getTerminalInfo();
  },

  methods: {
    getTerminalInfo() {
      this.loading = true;

      this.zvtAxios.get('/info').then((res) => {
        this.loading = false;

        if (res.status === 200) {
          this.ready = res.data.ready;
        }
      }).catch((err) => {
        this.loading = false;

        // ZVT SERVER ERROR
        if(typeof err.response !== "undefined")
          if(err.hasOwnProperty("response"))
            if(err.response.hasOwnProperty("data"))
              if(err.response.data.hasOwnProperty("message"))
                return Events.$emit("showSnackbar", {
                  message: err.response.data.message,
                  color: "error"
                });
        
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      });
    },
    diagnosis() {
      this.loadingDiagnosis = true;

      this.zvtAxios.post('/diagnosis').then((res) => {
        if (res.status === 200) {
          //PREPARE PRINTING
          let print = [];

          res.data.printing.forEach((item) => {
            print.push({text: item});
          });

          //ADD FINAL CUT
          print.push({cut: true});

          //START PRINTING
          printDataFromPrinter(this.invoicePrinter,print).then(()=>{

          }).catch((err)=>{

            this.reprintPayload = print;
            this.showReprintDialog = true;

            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }).finally(()=>{
            this.loadingDiagnosis = false;
          })
        } else {
          this.loadingDiagnosis = false;

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        this.loadingDiagnosis = false;

        // ZVT SERVER ERROR
        if(err.hasOwnProperty("response"))
          if(err.response.hasOwnProperty("data"))
            if(err.response.data.hasOwnProperty("message"))
              return Events.$emit("showSnackbar", {
                message: err.response.data.message,
                color: "error"
              });

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      })
    },
    dailyReport() {
      this.loadingDailyReport = true;

      this.zvtAxios.post('/daily-report').then((res) => {
        if (res.status === 200) {
          //PREPARE PRINTING
          let print = [];

          res.data.printing.forEach((item) => {
            print.push({text: item});
          });

          //ADD FINAL CUT
          print.push({cut: true});

          //START PRINTING
          printDataFromPrinter(this.invoicePrinter,print).then(()=>{

          }).catch((err)=>{

            this.reprintPayload = print;
            this.showReprintDialog = true;

            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }).finally(()=>{
            this.loadingDailyReport = false;
          })

        } else {
          this.loadingDailyReport = false;

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        this.loadingDailyReport = false;

        // ZVT SERVER ERROR
        if(err.hasOwnProperty("response"))
          if(err.response.hasOwnProperty("data"))
            if(err.response.data.hasOwnProperty("message"))
              return Events.$emit("showSnackbar", {
                message: err.response.data.message,
                color: "error"
              });

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      })
    },
    unregister() {
      this.loadingUnregister = true;

      this.zvtAxios.post('/unregister').then((res) => {
        this.loadingUnregister = false;

        if (res.status === 200) {
          this.getTerminalInfo();
        }
      }).catch((err) => {
        this.loadingUnregister = false;

        // ZVT SERVER ERROR
        if(err.hasOwnProperty("response"))
          if(err.response.hasOwnProperty("data"))
            if(err.response.data.hasOwnProperty("message"))
              return Events.$emit("showSnackbar", {
                message: err.response.data.message,
                color: "error"
              });

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      })
    },
    register() {
      this.loadingRegister = true;

      this.zvtAxios.post('/register').then((res) => {
        this.loadingRegister = false;

        if (res.status === 200) {
          this.getTerminalInfo();
        }
      }).catch((err) => {
        this.loadingRegister = false;

        // ZVT SERVER ERROR
        if(err.hasOwnProperty("response"))
          if(err.response.hasOwnProperty("data"))
            if(err.response.data.hasOwnProperty("message"))
              return Events.$emit("showSnackbar", {
                message: err.response.data.message,
                color: "error"
              });

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      });
    },
    reprint() {
      this.loadingReprint = true;
      printDataFromPrinter(this.invoicePrinter,this.reprintPayload).then(()=>{

      }).catch((err)=>{

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loadingReprint = false;
      })

    },
  }
}
</script>